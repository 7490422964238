










import Colorizable from '@/mixins/Colorizable';
import ClassListComposer from '@/mixins/ClassListComposer';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'sidenavButton',
})
export default class SidenavButton extends mixins(Colorizable, ClassListComposer) {
  baseClass = 'sidenav-button';

  @Prop({ default: '' }) value: string;
  @Prop({ default: false }) selected: boolean;
  @Prop({ default: '' }) navigateTo: string;
  @Prop({ default: 'light-100' }) color: string;

  goToTheRoute(path: string) {
    if (this.$route.path !== path) return this.$router.push(path);
  }
}
