






import ButtonLike, { ButtonType } from '@/mixins/ButtonLike';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Tab extends mixins(ButtonLike) {
  baseClass = 'tab';
  classList: Array<string> = ['tab--hoverable'];

  @Prop({ default: 'outline' }) type: ButtonType;
  @Prop({ default: 'dim' }) color: string;
  @Prop() customColor: string;

  public get style() {
    if (!this.customColor) return {};

    return {
      borderColor: `${this.customColor}`,
      backgroundColor: this.type === 'plain' ? `${this.customColor}` : '',
      color: this.type === 'plain' ? '#fff' : '',
    };
  }
}
